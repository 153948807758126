import "./Button.css";

export default function Button({
  label,
  className,
  handleClick,
  disabled,
  id,
}) {
  return (
    <div className={className + (disabled ? " disabled" : "")}>
      <button
        id={id}
        data-testid="button"
        onClick={handleClick}
        disabled={disabled}
      >
        {label}
      </button>
    </div>
  );
}
