import "./InvalidRequest.css";

export default function InvalidRequest() {
  return (
    <div className="invalid-request">
      <div id="invalidRequest">
        <div style={{ fontSize: "22px", fontWeight: 600, textAlign: "center" }}>
          The Link You’ve Clicked Has Expired
        </div>

        <div style={{ textAlign: "center", padding: "40px 0" }}>
          <img
            alt="invalid-request logo"
            style={{ height: "200px", width: "250px" }}
            src="/logos/invalidrequest.svg"
          />
        </div>

        <div
          style={{
            color: "#495662",
            fontSize: "16px",
            lineHeight: "20px",
            width: "700px",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>
            Please get in touch with your investment advisor and let them know
            you received this error. You can also write to us at{" "}
            <a href="mailto:support@winvesta.in" style={{ color: "#0E91A0" }}>
              support@winvesta.in
            </a>{" "}
            for the same.
          </div>
        </div>
      </div>
    </div>
  );
}
