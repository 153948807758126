export default function DocLink({ link, text, clickHandler, id }) {
  return (
    <a
      id={id}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        textDecoration: "none",
        fontWeight: 600,
        color: "#495662",
        marginBottom: 16,
        cursor: "pointer",
      }}
      onClick={text === "Investment Advisor Agreement" ? clickHandler : null}
    >
      <div
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: "#F5F5F5",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          flexShrink: 0,
        }}
      >
        <img src="/icons/doc.svg" alt="doc link" />
      </div>
      <div
        style={{
          marginLeft: "12px",
          marginRight: "40px",
        }}
      >
        {text}
      </div>
    </a>
  );
}
