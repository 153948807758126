import "./RadioField.css";

export default function RadioField({
  label,
  className,
  value,
  onChange,
  options,
  id,
}) {
  return (
    <div className={className}>
      <label>
        <div id={id + "-label"}>{label}</div>
        <div>
          {options.map((val, index) => {
            return (
              <div key={index}>
                {val.value === value && (
                  <div id={id + "-" + val.label} className="empty">
                    <div className="filled"></div>
                  </div>
                )}
                {val.value !== value && (
                  <input
                    id={id + "-" + val.label}
                    type="radio"
                    value={val.value}
                    onChange={onChange}
                    checked={val.value === value}
                  />
                )}
                <span id={id + "-" + val.label + "-label"}>{val.label}</span>
              </div>
            );
          })}
        </div>
      </label>
    </div>
  );
}
