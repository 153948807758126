import "./DateField.css";

export default function DateField({
  className,
  label,
  value,
  onChange,
  validationObj,
  id,
}) {
  return (
    <div
      className={
        className + (validationObj && validationObj.isInvalid ? " invalid" : "")
      }
    >
      <label>
        <div id={id + "-label"}>{label}</div>
        <input
          id={id}
          data-testid="date-field"
          className="dob"
          type="date"
          value={value}
          onChange={onChange}
        />
      </label>
      {validationObj && validationObj.isInvalid && (
        <div id={id + "-error"} className="red-text">
          {validationObj.error}
        </div>
      )}
    </div>
  );
}
