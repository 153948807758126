export const validateForm3 = (form3) => {
  console.log(form3);
  const validationResult = {};

  if (!form3.panFront && !form3.panBack) {
    validationResult.pan = true;
    validationResult.panError = "Please upload atleast one image";
  }

  if (!form3.aadharFront && !form3.aadharBack) {
    validationResult.aadhar = true;
    validationResult.aadharError = "Please upload atleast one image";
  }

  return validationResult;
};
