// import { Modal } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Form4 from "../../components/InviteClient/Form4";
// import TncDocModal from "../../components/Modals/TncDocModal";
import { clientsApiCaller } from "../../utils/network-requests/clients-api-call";

export default function Form4Container({
  clientId,
  setError,
  setClientModal,
  setMessage,
  partnerId,
  mcaInvite,
}) {
  const history = useHistory();

  const submitHandler = (e) => {
    e.preventDefault();
    setClientModal("loader");

    const reqBody = {
      client_id: clientId,
      disclosure_details: {
        w8ben_signed: true,
        alp_agreement_accepted: true,
        winvesta_america_agreement_accepted: true,
        partner_tnc: true,
      },
      investment_details: {
        is_pep: false,
        is_finra_or_affiliate: false,
        is_director: false,
      },
    };

    clientsApiCaller
      .putForm({ reqBody })
      .then(({ response, error, unauthorized }) => {
        if (unauthorized) {
          setClientModal("");
          setError(true);
        } else if (!error) {
          setClientModal("");
          // if (mcaInvite)
          //   window.location.replace(
          //     "https://pages.razorpay.com/pl_HQ8ax2DeRfObGs/view"
          //   );
          history.push("/thank-you");
        } else {
          setClientModal("message");
          setMessage(JSON.stringify(response));
        }
      });
  };

  // const [doc, setDoc] = useState(null);
  const [docBuffer, setDocBuffer] = useState(null);
  // const [docModal, setDocModal] = useState("");

  const downloadPdf = (docBuffer) => {
    const href = window.URL.createObjectURL(
      new Blob([docBuffer], { type: "application/pdf" })
    );

    window.open(href);

    // const link = document.createElement("a");
    // link.download = "t&c.pdf";
    // link.href = href;
    // link.click();
    return;
  };

  const fetchDoc = (e) => {
    e.preventDefault();

    if (docBuffer) {
      downloadPdf(docBuffer);
      return;
    }
    // } else if (doc) {
    //   setDocModal("tncDoc");
    //   return;
    // }

    setClientModal("loader");
    clientsApiCaller
      .getDoc({ params: { partnerId } })
      .then(({ response, error, unauthorized }) => {
        setClientModal("");
        if (unauthorized) {
          setError(true);
        } else if (error) {
          setClientModal("message");
          setMessage(
            "There was an error while fetching the document. Please contact the support!"
          );
        } else {
          // setDoc(response.pdf);
          setDocBuffer(response.buffer);
          downloadPdf(response.buffer);
        }
      });
  };

  // useEffect(() => {
  //   if (doc && docBuffer) {
  //     window.innerWidth < 1000 ? downloadPdf(docBuffer) : setDocModal("tncDoc");
  //   }
  // }, [doc, docBuffer]);

  return (
    <div>
      <Form4
        submitHandler={submitHandler}
        fetchDoc={fetchDoc}
        mcaInvite={mcaInvite}
      />
      {/* {docModal && (
        <Modal
          open={docModal === "tncDoc"}
          onClose={() => setDocModal("")}
          aria-labelledby="tncDocModal"
          aria-describedby="tncDocModal-description"
        >
          <TncDocModal handleClick={() => setDocModal("")} doc={doc} />
        </Modal>
      )} */}
    </div>
  );
}
