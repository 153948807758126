import { useEffect, useState } from "react";
import * as Onfido from "onfido-sdk-ui";
import { clientsApiCaller } from "./utils/network-requests/clients-api-call";
import "./OnfidoSdk.css";
import { useHistory } from "react-router";

export default function OnfidoSdk({
  setError,
  setClientModal,
  setMessage,
  changeStage,
}) {
  const [loading, setLoading] = useState(false);
  const [onfidoInstance, setOnfidoInstance] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const initOnfido = async () => {
      try {
        setLoading(true);
        setClientModal("loader");
        const reqBody = {
          doc_type: 1,
          referrer: window.location.origin + "/",
        };
        const { response, error, unauthorized } =
          await clientsApiCaller.initSDK({ reqBody });

        if (unauthorized) {
          setClientModal("");
          setError(true);
        } else if (error) {
          setClientModal("message");
          setMessage(JSON.stringify(response));
        } else {
          const instance = Onfido.init({
            useMemoryHistory: true,
            useModal: false,
            token: response.sdk_token,
            onComplete: (data) => {
              // callback for when everything is complete
              console.log("Everything is complete", data);
              setClientModal("loader");
              clientsApiCaller
                .generateCheck({
                  reqBody: {
                    applicant_id: response.id,
                    doc_type: 1,
                  },
                })
                .then(({ response, error, unauthorized }) => {
                  if (unauthorized) {
                    setClientModal("");
                    setError(true);
                  } else if (!error) {
                    setClientModal("");
                    changeStage(5);
                    history.replace("/onboarding");
                  } else {
                    setClientModal("message");
                    setMessage(JSON.stringify(response));
                  }
                });
            },
            steps: [
              {
                type: "face",
                options: {
                  requestedVariant: "standard",
                },
              },
              // "complete",
            ],
          });

          setClientModal("");
          setOnfidoInstance(instance);
        }
        setLoading(false);
      } catch (err) {
        console.log("err:", err.message, err.request);
      }
    };
    initOnfido();
    return () => {
      console.log("tear down", onfidoInstance);
      onfidoInstance && onfidoInstance.tearDown();
    };
  }, []);

  return <div id="onfido-mount">{loading && <div>Loading...</div>}</div>;
}
