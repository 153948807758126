import "./Form2.css";
import TextFieldContainer from "../../containers/Form/TextField/TextFieldContainer";
import Button from "../FormComponents/Button/Button";

export default function Form2({
  form2,
  setForm2,
  validateForm2Result,
  submitHandler,
  goBack,
  skipNow,
  disableSumbit,
}) {
  return (
    <form id="invite-form2" className="invite-client-form2">
      <div>
        <TextFieldContainer
          id="invite-form2-employer_name"
          label="Name of Employer"
          setData={setForm2}
          data={form2}
          dataField="employer_name"
          className="invite-text-field"
          validationObj={{
            isInvalid: validateForm2Result.employer_name,
            error: validateForm2Result.employer_name_error,
          }}
        />

        <TextFieldContainer
          id="invite-form2-position"
          label="Position"
          setData={setForm2}
          data={form2}
          dataField="employment_position"
          className="invite-text-field"
          validationObj={{
            isInvalid: validateForm2Result.employment_position,
            error: validateForm2Result.employment_position_error,
          }}
        />
      </div>

      <div>
        <TextFieldContainer
          id="invite-form2-employer_address"
          label="Employer Address"
          setData={setForm2}
          data={form2}
          dataField="employer_address"
          className="invite-text-field-full"
          validationObj={{
            isInvalid: validateForm2Result.employer_address,
            error: validateForm2Result.employer_address_error,
          }}
        />
      </div>

      <div
        style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
        id="invite-form2-skip"
        className="skip-text"
        onClick={skipNow}
      >
        Skip for now
      </div>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          id="invite-form2-goback-button"
          label="BACK"
          className="goback-button"
          handleClick={goBack}
        />
        <Button
          id="invite-form2-submit-button"
          label="CONFIRM DETAILS"
          className="form1-button form2-button"
          handleClick={submitHandler}
          // disabled={disableSumbit()}
        />
      </div>
    </form>
  );
}
