import DateField from "../../../components/FormComponents/DateField/DateField";

export default function DateFieldContainer({
  label,
  className,
  setData,
  data,
  dataField,
  validationObj,
  id,
}) {
  const onChange = (e) => {
    setData({ ...data, [dataField]: e.target.value });
  };

  return (
    <DateField
      id={id}
      label={label}
      className={className}
      value={data[dataField]}
      onChange={onChange}
      validationObj={validationObj}
    />
  );
}
