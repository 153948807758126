import "./Form1.css";
import TextFieldContainer from "../../containers/Form/TextField/TextFieldContainer";
import SelectFieldContainer from "../../containers/Form/Select/SelectFieldContainer";
import DateFieldContainer from "../../containers/Form/Date/DateFieldContainer";
import RadioFieldContainer from "../../containers/Form/Radio/RadioFieldContainer";
import Button from "../FormComponents/Button/Button.jsx";
import {
  employment_status_arr,
  funding_sources_arr,
} from "../../utils/Constants/InviteForms";

export default function Form1({
  countryCode,
  nations,
  form1,
  setForm1,
  validateForm1Result,
  submitHandler,
  disableSumbit,
}) {
  return (
    <form id="invite-form1" className="invite-client-form1">
      <div style={{ marginTop: 16 }}>
        <TextFieldContainer
          id="invite-form1-fullname"
          label="Full Name (As on client's PAN Card)"
          setData={setForm1}
          data={form1}
          dataField="fullname"
          className="invite-text-field"
          validationObj={{
            isInvalid: validateForm1Result.fullname,
            error: validateForm1Result.fullname_error,
          }}
          notEditable={true}
        />
        <DateFieldContainer
          id="invite-form1-date_of_birth"
          label="Date of Birth"
          className="invite-date-field"
          setData={setForm1}
          data={form1}
          dataField="date_of_birth"
          validationObj={{
            isInvalid: validateForm1Result.date_of_birth,
            error: validateForm1Result.date_of_birth_error,
          }}
        />
      </div>
      <div></div>
      <div>
        <div style={{ position: "relative" }}>
          <div
            style={{
              backgroundColor: "rgba(204, 210, 218, 0.2)",
              border: "1px solid #ccd2da",
              borderRadius: "6px  0 0 6px",
              width: 100,
              height: 45,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              height: 46,
              paddingLeft: 6,
              color: "#495622",
              position: "absolute",
              top: 27,
            }}
          >
            <img
              alt={countryCode.label}
              src={`/countries/${countryCode.label}.png`}
              style={{ marginRight: 8 }}
            />
            {countryCode.code}
          </div>

          <TextFieldContainer
            id="invite-form1-phone_number"
            label="Mobile Number"
            setData={setForm1}
            data={form1}
            dataField="phone_number"
            className="invite-text-field invite-mob-field"
            extraComponent="mob"
            validationObj={{
              isInvalid: validateForm1Result.phone_number,
              error: validateForm1Result.phone_number_error,
            }}
            notEditable={true}
          />
        </div>

        <TextFieldContainer
          id="invite-form1-email"
          label="Email ID"
          setData={setForm1}
          data={form1}
          dataField="email"
          className="invite-text-field"
          validationObj={{
            isInvalid: validateForm1Result.email,
            error: validateForm1Result.email_error,
          }}
          notEditable={true}
        />
      </div>
      <div>
        <RadioFieldContainer
          id="invite-form1-gender"
          label="Gender"
          setData={setForm1}
          data={form1}
          dataField="gender"
          className="invite-radio-field"
          options={[
            { value: "male", label: "Male" },
            { value: "female", label: "Female" },
          ]}
        />
      </div>
      <div>
        <SelectFieldContainer
          id="invite-form1-country_of_residence"
          label="Country of Residence"
          setData={setForm1}
          data={form1}
          dataField="country_of_residence"
          className="invite-select-field"
          options={nations.map((obj) => ({
            value: obj.label,
            label: obj.label,
          }))}
        />

        <SelectFieldContainer
          id="invite-form1-employement_status"
          label="Employment Status"
          setData={setForm1}
          data={form1}
          dataField="employment_status"
          className="invite-select-field"
          options={employment_status_arr}
          validationObj={{
            isInvalid: validateForm1Result.employment_status,
            error: validateForm1Result.employment_status_error,
          }}
        />
      </div>

      <div>
        <SelectFieldContainer
          id="invite-form1-funding_source"
          label="Funding Source"
          setData={setForm1}
          data={form1}
          dataField="funding_source"
          className="invite-select-field"
          options={funding_sources_arr}
          validationObj={{
            isInvalid: validateForm1Result.funding_source,
            error: validateForm1Result.funding_source_error,
          }}
        />

        <TextFieldContainer
          id="invite-form1-investible_assets"
          label="Investible Assets"
          setData={setForm1}
          data={form1}
          dataField="investible_assets"
          className="invite-text-field invite-inr-field"
          extraComponent="inr"
          validationObj={{
            isInvalid: validateForm1Result.investible_assets,
            error: validateForm1Result.investible_assets_error,
          }}
        />
      </div>
      <div>
        <Button
          id="invite-form1-submit-button"
          label="SAVE & PROCEED"
          className="form1-button"
          handleClick={submitHandler}
          // disabled={disableSumbit()}
        />
      </div>
    </form>
  );
}
