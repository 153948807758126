import "./DocImage.css";

export default function DocImage({ img, front, clickHandler, id }) {
  return (
    (img && (
      <div id={id} className="docImage">
        <div id={id + "-remove"} onClick={clickHandler}>
          <img src="icons/removeImage.svg" alt="remove icon" />
        </div>
        <div>
          <img
            id={id + "-img"}
            src={img}
            alt={front ? "front image" : "back image"}
          />
        </div>
        <div>{front ? "Front Side" : "Back Side"}</div>
      </div>
    )) ||
    null
  );
}
