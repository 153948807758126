export const employment_status_arr = [
  { value: 0, label: "Employed" },
  { value: 1, label: "Retired" },
  { value: 2, label: "Student" },
  { value: 3, label: "Unemployed" },
];

export const investment_objective_arr = [
  { value: 0, label: "Long Term" },
  { value: 1, label: "Active Daily" },
  { value: 2, label: "Frequent" },
  { value: 3, label: "Infrequent" },
  { value: 4, label: "New" },
];

export const funding_sources_arr = [
  {
    value: "employment_income",
    label: "Employment",
  },
  {
    value: "investments",
    label: "Investments",
  },
  {
    value: "inheritance",
    label: "Inheritance",
  },
  {
    value: "business_income",
    label: "Business Income",
  },
  {
    value: "savings",
    label: "Savings",
  },
  {
    value: "family",
    label: "Family",
  },
];

export const old_funding_sources_map = {
  EMP: "employment_income",
  UNEMP: "employment_income",
  INVESTMENT: "investments",
  ROLLOVER: "investments",
  TRUST: "inheritance",
  GIFT: "inheritance",
  RENT: "business_income",
  SALE: "business_income",
  RETIRE: "savings",
  GAMBLING: "savings",
  SAVINGS: "savings",
  LEGAL: "family",
  FAMILY: "family",
  INSURANCE: "family",
};
