import "./SelectField.css";

export default function SelectField({
  label,
  className,
  value,
  onChange,
  options,
  validationObj,
  multiple,
  id,
}) {
  return (
    <div
      className={
        className + (validationObj && validationObj.isInvalid ? " invalid" : "")
      }
    >
      <label>
        <div id={id + "-label"}>{label}</div>
        <select
          id={id}
          data-testid="select-field"
          value={value}
          onChange={onChange}
          className="select-css"
          multiple={multiple}
          size={1}
        >
          {options.map((val, index) => {
            return (
              <option id={id + index} key={index} value={val.value}>
                {val.label}
              </option>
            );
          })}
        </select>
      </label>
      {validationObj && validationObj.isInvalid && (
        <div id={id + "-error"} className="red-text">
          {validationObj.error}
        </div>
      )}
    </div>
  );
}
