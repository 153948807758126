import Button from "../FormComponents/Button/Button";
import "./ClientModal.css";

export default function ClientModal({ message, handleClick }) {
  return (
    <div id="clientModal">
      <div>{message}</div>
      <Button
        id="clientModal-button"
        handleClick={handleClick}
        label={"CLOSE"}
        className="client-modal-button"
      />
    </div>
  );
}
