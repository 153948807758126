import "./ThankYou.css";

export default function ThankYou() {
  return (
    <div className="thank-you">
      <div id="thankyou">
        <div style={{ fontSize: "22px", fontWeight: 600, textAlign: "center" }}>
          Thank you for signing up!
        </div>

        <div style={{ textAlign: "center", padding: "40px 0" }}>
          <img
            alt="thank-you logo"
            style={{ height: "160px", width: "260px" }}
            src="/logos/thankyou.svg"
          />
        </div>

        <div
          style={{
            color: "#495662",
            fontSize: "16px",
            lineHeight: "20px",
            width: "700px",
            margin: "auto",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div>
            Thank you for confirming your details and verifying your identity.
          </div>
          <div>
            <div style={{paddingRight: "10px"}}>Your documents are being reviewed and you will receive an email
            shortly.</div>
          </div>
          <div>Our team will get in touch with you for any clarifications.</div>
        </div>
      </div>
    </div>
  );
}
