import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Stages from "../../components/Stages/Stages";
import OnfidoSdk from "../../OnfidoSdk";
import { old_funding_sources_map } from "../../utils/Constants/InviteForms";
import { clientsApiCaller } from "../../utils/network-requests/clients-api-call";
import Form1Container from "./Form1Container";
import Form2Container from "./Form2Container";
import Form3Container from "./Form3Container";
import Form4Container from "./Form4Container";

const mcaInviteEnabled = (partnerId) => {
  return ["132f8094-5749-4a23-8562-5b2d7b89ab69"].includes(partnerId);
};

export default function ClientsFormContainer({
  setClientModal,
  setMessage,
  changeView,
  clientId,
  setError,
}) {
  const [stage, setStage] = useState(1);
  const changeStage = (value) => {
    setStage(value);
  };

  const [status, setStatus] = useState(0);
  const [form1, setForm1] = useState({
    fullname: "",
    date_of_birth: "",
    phone_number: "",
    email: "",
    gender: "male",
    country_of_residence: "India",
    funding_source: "savings",
    employment_status: 3,
    investible_assets: "",
  });
  const [validateForm1Result, setValidateForm1Result] = useState({});

  const [form2, setForm2] = useState({
    employer_name: "",
    employment_position: "",
    employer_address: "",
  });
  const [validateForm2Result, setValidateForm2Result] = useState({});

  const [form3, setForm3] = useState({
    panFront: "",
    panBack: "",
    aadharFront: "",
    aadharBack: "",
  });
  const [validateForm3Result, setValidateForm3Result] = useState({});
  const [documentIds, setDocumentIds] = useState({
    panFront: "",
    panBack: "",
    aadharFront: "",
    aadharBack: "",
  });

  const [nations, setNations] = useState([{ value: "IND", label: "India" }]);
  const [countryCode, setCountryCode] = useState({ label: "IND", code: "+91" });
  const [dataLoaded, setDataLoaded] = useState(1);

  const [partnerId, setPartnerId] = useState(null);
  const [basicDetails, setBasicDetails] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setClientModal("loader");
    Promise.all([
      clientsApiCaller.getCountry(),
      clientsApiCaller.getForm({
        params: { client_id: clientId },
      }),
    ]).then(([result1, result2]) => {
      setClientModal("");
      if (result1.unauthorized || result2.unauthorized) {
        setClientModal("");
        setError(true);
      } else {
        const obj = {};
        if (!result1.error) {
          const arr = [];
          const countryCodeObj = { label: "", code: "" };
          if (Array.isArray(result1.response)) {
            result1.response.forEach((obj) => {
              if (obj.is_supported) {
                arr.push({ value: obj.iso_alpha_3_code, label: obj.name });
              }
            });
            arr.sort((a, b) =>
              a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            );
            obj.arr = arr;
            for (let val of result1.response) {
              console.log(
                val.code,
                result2.response?.basic_details?.country_code
              );
              if (
                val.code.slice(2, -1) ===
                result2.response?.basic_details?.country_code
              ) {
                countryCodeObj.label = val.iso_alpha_3_code;
                countryCodeObj.code = val.code.slice(1, -1);
              }
            }
            obj.countryCodeObj = countryCodeObj;
          }
        } else {
          setClientModal("message");
          setMessage("Some error occured while fetching the countries list.");
        }
        let partner;
        if (!result2.error) {
          const {
            basic_details,
            investment_details,
            kyc_details,
            disclosure_details,
          } = result2.response || {};
          obj.basicDetails = basic_details;
          partner = basic_details?.partner;

          const {
            w8ben_signed_by,
            alp_agreement_accepted,
            winvesta_america_agreement_accepted,
            partner_tnc,
          } = disclosure_details || {};
          if (
            w8ben_signed_by &&
            alp_agreement_accepted &&
            winvesta_america_agreement_accepted &&
            partner_tnc
          ) {
            // if (mcaInviteEnabled(partner))
            //   window.location.replace(
            //     "https://pages.razorpay.com/pl_HQ8ax2DeRfObGs/view"
            //   );
            history.push("/thank-you");
            return;
          }

          const funding_source_key = investment_details?.funding_sources?.[0];
          const funding_source =
            funding_source_key in old_funding_sources_map
              ? old_funding_sources_map[funding_source_key]
              : funding_source_key;

          const {
            employment_status,
            employer_name,
            employment_position,
            employer_address,
          } = investment_details || {};
          const investible_assets = parseInt(
            investment_details?.investible_assets * 75
          );
          const gender = basic_details?.gender === 1 ? "male" : "female";

          const {
            fullname,
            date_of_birth,
            phone_number,
            email,
            country_of_residence,
          } = basic_details || {};

          obj.form1 = {
            fullname,
            date_of_birth,
            phone_number,
            email,
            gender,
            country_of_residence,
            funding_source,
            employment_status,
            investible_assets,
          };
          obj.form2 = { employer_name, employment_position, employer_address };

          const { status } = kyc_details;
          obj.status = status;
        } else {
          setClientModal("message");
          setMessage("Some error occured while fetching the form data.");
        }
        !result1.error && setNations(obj.arr);
        !result1.error && setCountryCode(obj.countryCodeObj);
        !result2.error && setForm1(obj.form1);
        !result2.error && setForm2(obj.form2);
        !result2.error && setBasicDetails(obj.basicDetails);
        !result2.error && setStatus(obj.status);
        !result2.error && setPartnerId(partner);
        setDataLoaded((l) => l + 1);
        if (!result1.error && !result2.error) setClientModal("");
      }
    });
  }, []);

  // useEffect(() => {
  //   if (basicDetails) {
  //     window.fcWidget?.user.setFirstName(basicDetails.first_name);
  //     window.fcWidget?.user.setEmail(basicDetails?.email);
  //     window.fcWidget?.user.setProperties({
  //       plan: "Pro",
  //       status: "Active",
  //     });
  //     window.fcWidget?.setExternalId(basicDetails?.tracking_id);
  //   }
  // }, [basicDetails]);

  const mcaInvite = mcaInviteEnabled(partnerId);

  return (
    <div>
      {stage <= 4 && (
        <div className="forms-text">
          <div id="forms-text-lt3">
            Your account will be created using the details mentioned below.
            Please edit details in case you wish to change your details and
            click on “Confirm” to proceed with KYC verification.
          </div>
        </div>
      )}
      {stage === 5 && (
        <div className="forms-text">
          <div id="forms-text-4">
            Your account will be created using the details mentioned below.
            Please read all Terms & Conditions before you proceed with KYC
            verification.
          </div>
        </div>
      )}
      <Stages stage={stage} />
      {stage === 1 && (
        <Form1Container
          setError={setError}
          setClientModal={setClientModal}
          setMessage={setMessage}
          clientId={clientId}
          nations={nations}
          countryCode={countryCode}
          form1={form1}
          setForm1={setForm1}
          changeStage={changeStage}
          status={status}
          validateForm1Result={validateForm1Result}
          setValidateForm1Result={setValidateForm1Result}
        />
      )}
      {stage === 2 && (
        <Form2Container
          setError={setError}
          setClientModal={setClientModal}
          setMessage={setMessage}
          clientId={clientId}
          form2={form2}
          setForm2={setForm2}
          changeStage={changeStage}
          status={status}
          validateForm2Result={validateForm2Result}
          setValidateForm2Result={setValidateForm2Result}
        />
      )}
      {stage === 3 && (
        <Form3Container
          setError={setError}
          setClientModal={setClientModal}
          setMessage={setMessage}
          documentIds={documentIds}
          setDocumentIds={setDocumentIds}
          clientId={clientId}
          form3={form3}
          form1={form1}
          setForm3={setForm3}
          changeStage={changeStage}
          status={status}
          validateForm3Result={validateForm3Result}
          setValidateForm3Result={setValidateForm3Result}
          changeView={changeView}
        />
      )}
      {stage === 4 && (
        <div id="video-verification" className="video-verification">
          <div>
            <div
              style={{
                margin: "10px auto",
                width: 300,
                display: "flex",
                justifyContent: "center",
                fontSize: 22,
                fontWeight: 600,
              }}
            >
              Selfie Verification
            </div>
          </div>
          <div
            style={{
              margin: "20px auto",
              width: 300,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <OnfidoSdk
                setClientModal={setClientModal}
                setMessage={setMessage}
                setError={setError}
                changeStage={changeStage}
              />
            </div>
          </div>
        </div>
      )}
      {stage === 5 && (
        <Form4Container
          clientId={clientId}
          setError={setError}
          setClientModal={setClientModal}
          setMessage={setMessage}
          partnerId={partnerId}
          mcaInvite={mcaInvite}
        />
      )}
    </div>
  );
}
