import "./TextField.css";

export default function TextField({
  className,
  label,
  bind,
  extraComponent,
  validationObj,
  notEditable,
  show,
  setShow,
  id,
}) {
  return (
    <div>
      <div
        id={id + "-container"}
        className={
          className +
          (validationObj && validationObj.isInvalid ? " invalid" : "")
        }
        onMouseOver={() => setShow(true)}
        onMouseOut={() => setShow(false)}
      >
        <label>
          <div id={id + "-label"}>{label}</div>
          {/* {extraComponent === "mob" && (
            <div id="mob">
              <img src="icons/india.svg" alt="india icon" />
              +91
            </div>
          )} */}
          {extraComponent === "inr" && (
            <div
              style={{
                position: "absolute",
                top: 33,
                left: 12,
                color: "#495662",
                fontSize: 17
              }}
            >
              ₹
            </div>
          )}
          <input
            id={id}
            data-testid="text-field"
            type="text"
            {...bind}
            disabled={notEditable}
            style={{
              marginLeft: extraComponent === "mob" ? 100 : null,
              width: extraComponent === "mob" ? 260 : 360,
              paddingLeft: extraComponent === "inr" ? 26 : 16,
            }}
            required
          />
        </label>
        {validationObj && validationObj.isInvalid && (
          <div id={id + "-error"} className="red-text">
            {validationObj.error}
          </div>
        )}
        {extraComponent === "inr" && (
          <div
            id={id + "-inr"}
            style={{
              position: "absolute",
              color: "#9DA2B0",
              top: 35,
              right: 5,
            }}
          >
            {isFinite(bind.value / 75)
              ? "approx. $" + parseInt(bind.value / 75)
              : ""}
          </div>
        )}
      </div>
      {notEditable && (
        <div
          id={id + "-not-editable"}
          style={{
            visibility: show ? "visible" : "hidden",
          }}
          className="not-editable"
        >
          Please contact support@winvesta.in to make changes.
        </div>
      )}
    </div>
  );
}
