import { apiCaller } from "./api-call";
import { baseUrl } from "../Constants/UrlList";

function getHeader() {
  return {
    Authorization: `Bearer ${sessionStorage.getItem("order_token")}`,
  };
}

export const ordersApiCaller = {
  paginatedInstruments: async function ({ params }) {
    const url =
      "/client-order/instruments/paginated/?client_id=" +
      sessionStorage.getItem("client_id") +
      "&query=" +
      params.query;

    const { response, error, status } = await apiCaller.get({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      headers: getHeader(),
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    return {
      response,
      error,
      unauthorized: false,
    };
  },
  getQuoteId: async function ({ symbol }) {
    const url = "/client-order/alp/nbbo-quote/" + symbol + "/";

    const { response, error, status } = await apiCaller.get({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      headers: getHeader(),
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    return {
      response,
      error,
      unauthorized: false,
    };
  },
  getOrders: async function () {
    const url =
      "/client-order/alp/orders/?page_number=1&order_id=" +
      sessionStorage.getItem("order_id");

    const { response, error, status } = await apiCaller.get({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      headers: getHeader(),
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    const response1 = response?.results?.[0] || {};
    const error1 = error;

    if (response1?.symbol) {
      const { response, error, unauthorized } =
        await ordersApiCaller.getQuoteId({
          symbol: response1?.symbol,
        });

      if (unauthorized) return { unauthorized };
      else return { response1, error1, response, error, unauthorized: false };
    }

    return {
      response1,
      error1,
      unauthorized: false,
    };
  },
  submitOrder: async function ({ reqBody }) {
    const url = "/client-order/alp/" + reqBody.action + "/";

    const { response, error, status } = await apiCaller.post({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      reqBody: {
        order_token: sessionStorage.getItem("order_token"),
        quote_id: reqBody.quote_id,
      },
      headers: getHeader(),
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    return {
      response,
      error,
      unauthorized: false,
    };
  },
};
