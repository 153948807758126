import Button from "../FormComponents/Button/Button";
import "./ReviewSellOrder.css";

export default function ReviewSellOrder({
  submitOrder,
  data: {
    orderType,
    amount,
    stockName,
    stockSymbol,
    stockPrice,
    limitPrice,
    numOfShares,
    expiry,
    commission,
    totalPrice,
    amountEst,
    qtyEst,
  },
}) {
  return (
    <div id="review-sell-order" className="review-sell-order">
      <div id="review-sell-order-message">
        Your investment advisor has placed a {orderType} order to sell{" "}
        {numOfShares} {qtyEst ? "(Est.)" : ""} shares of {stockName} on your
        behalf. Kindly check the details mentioned below:
      </div>
      <div>
        <div>
          <div>Stock</div>
          <div id="review-sell-order-chosen-stock">
            <div>{stockSymbol}</div> <div>({stockName}</div>
            <span style={{ fontWeight: "400" }}>)</span>
          </div>
        </div>
        <div>
          <div>Order Type</div>
          <div id="review-sell-order-chosen-type">
            Sell <span style={{ fontWeight: "400" }}>({orderType})</span>
          </div>
        </div>
        <div>
          <div>Latest Price</div>
          <div id="review-sell-order-latest-price">${stockPrice}</div>
        </div>
        <div>
          <div>No. of shares {qtyEst ? "(Est.)" : ""}</div>
          <div>{numOfShares}</div>
        </div>
        {orderType !== "Market" && (
          <div>
            <div>{orderType} Price (Est.)</div>
            <div id="review-sell-order-limitPrice">${limitPrice}</div>
          </div>
        )}
        <div>
          <div>Value {amountEst ? "(Est.)" : ""}</div>
          <div id="review-sell-order-value">${amount}</div>
        </div>
        {expiry && (
          <div>
            <div>Expiry</div>
            <div>{expiry}</div>
          </div>
        )}
      </div>
      <div style={{ display: "none" }}>
        <div>Fees</div>
        <div>
          <div>Commission</div>
          <div>${commission}</div>
        </div>
      </div>
      <div>
        <div>Net Proceeds {amountEst ? "(Est.)" : ""}</div>
        <div id="review-sell-order-net-proceeds" style={{ color: "#0E91A0" }}>
          ${totalPrice}
        </div>
      </div>

      <div></div>

      <div>
        <Button
          id="review-sell-order-reject-button"
          label="REJECT"
          handleClick={() => submitOrder("reject")}
          className="review-reject-order-button"
        />
        <Button
          id="review-sell-order-approve-button"
          label="APPROVE"
          handleClick={() => submitOrder("approve")}
          className="review-approve-order-button"
        />
      </div>
      <div id="review-sell-order-terms">
        Our Terms & Conditions and Order Execution Policy apply. With
        investment, your capital is at risk
      </div>
    </div>
  );
}
