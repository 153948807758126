import RadioField from "../../../components/FormComponents/RadioField/RadioField";

export default function RadioFieldContainer({
  label,
  className,
  setData,
  data,
  dataField,
  options,
  id,
}) {
  const onChange = (e) => {
    setData({ ...data, [dataField]: e.target.value });
  };

  return (
    <RadioField
      id={id}
      label={label}
      className={className}
      value={data[dataField]}
      onChange={onChange}
      options={options}
    />
  );
}
