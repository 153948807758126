import Button from "../FormComponents/Button/Button";
import "./ConfirmOrderModal.css";

export default function ConfirmOrderModal({ heading, message, handleClick }) {
  return (
    <div id="confirmOrderModal">
      <div>{heading}</div>
      <div>{message}</div>
      <Button
        id="confirmOrderModal-button"
        handleClick={handleClick}
        label={"DONE"}
        className="confirm-modal-button"
      />
    </div>
  );
}
