import Button from "../FormComponents/Button/Button";
import "./Rule14BModal.css";

export default function Rule14BModal({ handleClick }) {
  return (
    <div id="rule14BModal">
      <div>
        Rule 14b-1(c) of the Securities Exchange Act, unless you object,
        requires us to disclose to an issuer, upon its request, the names,
        addresses, and securities positions of our customers who are beneficial
        owners of the issuer's securities held by us in nominee name. The issuer
        would be permitted to use your name and other related information for
        corporation communication only.
      </div>
      <Button
        id="rule14BModal-button"
        handleClick={handleClick}
        label={"CLOSE"}
        className="client-modal-button"
      />
    </div>
  );
}
