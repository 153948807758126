import ClientsFormContainer from "../../containers/Clients/ClientsFormContainer";
import ThankYou from "../ThankYou/ThanksYou";
import { CircularProgress, Modal } from "@material-ui/core";
import ClientModal from "../../components/Modals/ClientModal";
import Rule14BModal from "../Modals/Rule14BModal";
import InvalidRequest from "../Invalid/InvalidRequest";
import "./Main.css";
import { Switch, Redirect, Route } from "react-router-dom";
import OrderContainer from "../../containers/Order/OrderContainer";
import ConfirmOrderModal from "../Modals/ConfirmOrderModal";

export default function Main({
  error,
  setError,
  clientModal,
  setClientModal,
  message,
  setMessage,
  heading,
  setHeading,
  clientId,
}) {
  return (
    <Switch>
      <div>
        <div className="topbar">
          <div>
            <img src="/logos/winvestalogo.png" alt="company logo" />
          </div>
          <div id="topbar-heading">
            {window.location.pathname === "/onboarding"
              ? "Confirm Your Details"
              : window.location.pathname === "/order"
              ? "Order Confirmation"
              : ""}
          </div>
        </div>

        <div className="main-container">
          {error && <Redirect to="/invalid-request" />}
          <Route
            path="/onboarding"
            exact
            render={() => (
              <ClientsFormContainer
                setClientModal={setClientModal}
                setMessage={setMessage}
                setError={setError}
                clientId={clientId}
              />
            )}
          />
          <Route
            path="/order"
            exact
            render={() => (
              <OrderContainer
                setClientModal={setClientModal}
                setMessage={setMessage}
                clientId={clientId}
                setError={setError}
                setHeading={setHeading}
              />
            )}
          />
          <Route path="/thank-you" component={ThankYou} />
          <Route path="/invalid-request" component={InvalidRequest} />
        </div>
        {clientModal && (
          <Modal
            open={clientModal !== "" && clientModal !== "confirmOrder"}
            onClose={() => {
              if (clientModal !== "loader") {
                setClientModal("");
              }
            }}
            aria-labelledby="clientModal"
            aria-describedby="clientModal-description"
          >
            {clientModal === "loader" ? (
              <div
                style={{
                  padding: "24px",
                  width: "90px",
                  height: "90px",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <ClientModal
                message={message}
                handleClick={() => setClientModal("")}
              />
            )}
          </Modal>
        )}
        {clientModal && (
          <Modal
            open={clientModal === "rule14B"}
            onClose={() => {
              if (clientModal !== "loader") {
                setClientModal("");
              }
            }}
            aria-labelledby="rule14BModal"
            aria-describedby="rule14BModal-description"
          >
            <Rule14BModal handleClick={() => setClientModal("")} />
          </Modal>
        )}
        {clientModal && (
          <Modal
            open={clientModal === "confirmOrder"}
            onClose={() => {
              if (clientModal !== "loader") {
                setClientModal("");
              }
            }}
            aria-labelledby="confirmOrderModal"
            aria-describedby="confirmOrderModal-description"
          >
            <ConfirmOrderModal
              message={message}
              heading={heading}
              handleClick={() => setClientModal("")}
            />
          </Modal>
        )}
      </div>
    </Switch>
  );
}
