import TextFieldContainer from "../../containers/Form/TextField/TextFieldContainer.jsx";
import Button from "../FormComponents/Button/Button.jsx";
import DocLink from "./DocLink.jsx";
import "./Form4.css";

export default function Form4({ submitHandler, fetchDoc, mcaInvite }) {
  return (
    <div id="invite-form4" className="invite-client-form4">
      <div style={{ color: "#495662", marginBottom: "30px", marginTop: 12 }}>
        Please click through each of the links below. They will open in a new
        window each. After you’ve clicked through all, and click on{" "}
        <strong>Agree and Continue</strong> to proceed.
      </div>

      <div style={{ marginBottom: "30px" }}>
        <div className="form4-header">Terms & Conditions</div>
        <div style={{ textAlign: "center", color: "#495662" }}>
          Subject to KYC Approval
        </div>
        <div style={{ color: "#484649", marginTop: 16 }}>
          <div>
            I agree and confirm that these do not apply to me or my family
            members:
          </div>
          <ol style={{ marginLeft: 16 }}>
            <li>
              Affiliated or work with a US registered broker-dealer or FINRA.
            </li>
            <li>
              Senior executive at or a 10% or greater shareholder of a publicly
              traded company.
            </li>
            <li>A senior political figure.</li>
          </ol>
        </div>
        <div
          style={{
            marginTop: "30px",
            display: "flex",
          }}
        >
          <DocLink
            id="invite-form4-privacy-link"
            link="https://www.winvesta.in/Winvesta-Americas-Customer-Agreement.pdf"
            text="Winvesta Americas Agreement"
          />
          <DocLink
            id="invite-form4-terms-link"
            link="https://files.alpaca.markets/disclosures/library/AcctAppMarginAndCustAgmt.pdf"
            text="Alpaca Consumer Agreement"
          />
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          {mcaInvite && (
            <DocLink
              id="invite-form4-order-link"
              link="https://www.winvesta.in/wp-content/uploads/2021/05/Winvesta-MCA-Terms-and-Conditions.pdf"
              text="Winvesta Multi-Currency Account Agreement"
            />
          )}
          <DocLink
            id="invite-form4-order-link"
            text="Investment Advisor Agreement"
            clickHandler={fetchDoc}
          />
        </div>

        <div>
          {mcaInvite && (
            <DocLink
              id="invite-form4-order-link"
              link="https://www.winvesta.in/FinAtWork-Capital-Services-Winvesta-Ltd-MCA-Distribution-Agreement.pdf"
              text="Winvesta Multi-Currency Account Distribution Agreement"
            />
          )}
        </div>
      </div>

      <div style={{ color: "#495662" }}>
        <div className="form4-header">W-8BEN Agreement</div>
        <div>
          I certify that I am{" "}
          <strong>not a US citizen, US resident alien</strong> or other{" "}
          <strong>US person for US tax purposes</strong>, and I am submitting
          the applicable <strong>Form W-8 BEN</strong> with this form to certify
          my <strong>foreign status</strong> and, if applicable, claim tax
          treaty benefits.
        </div>
        <div>
          Under penalties of perjury, I declare that I have examined the
          information on this form and to the best of my knowledge and belief it
          is true, correct, and complete. I further certify under penalties of
          perjury that:
        </div>
        <div style={{ margin: 16, padding: "0 4px" }}>
          <ol>
            <li>
              I am the individual that is the beneficial owner (or am authorized
              to sign for the individual that is the beneficial owner) of all
              the income or proceeds to which this form relates or am using this
              form to document myself for chapter 4 purposes;
            </li>
            <li>
              The person named on line 1 of this form is not a U.S. person;
            </li>
            <li>
              This form relates to:
              <div>
                (a) income not effectively connected with the conduct of a trade
                or business in the United States;
              </div>
              <div>
                (b) income effectively connected with the conduct of a trade or
                business in the United States but is not subject to tax under an
                applicable income tax treaty;
              </div>
              <div>
                (c) the partner’s share of a partnership’s effectively connected
                taxable income; or
              </div>
              <div>
                (d) the partner’s amount realized from the transfer of a
                partnership interest subject to withholding under section
                1446(f);
              </div>
            </li>
            <li>
              The person named on line 1 of this form is a resident of the
              treaty country listed on line 9 of the form (if any) within the
              meaning of the income tax treaty between the United States and
              that country; and
            </li>
            <li>
              For broker transactions or barter exchanges, the beneficial owner
              is an exempt foreign person as defined in the instructions.
            </li>
          </ol>
        </div>
        <div>
          Furthermore, I authorize this form to be provided to any withholding
          agent that has control, receipt, or custody of the income of which I
          am the beneficial owner or any withholding agent that can disburse or
          make payments of the income of which I am the beneficial owner. I
          agree that I will submit a new form within 30 days if any
          certification made on this form becomes incorrect.
        </div>

        <div style={{ margin: "30px auto" }}>
          By clicking <strong>AGREE AND CONTINUE</strong>, I understand I am
          signing these agreements electronically, and that my electronic
          signature will have the same effect as physically signing and
          returning the Agreements.
        </div>
      </div>

      <div className="form3-buttons">
        <Button
          id="invite-form4-submit-button"
          label="AGREE AND CONTINUE"
          className="form1-button"
          handleClick={submitHandler}
        />
      </div>
    </div>
  );
}
