import Form1 from "../../components/InviteClient/Form1";
import { clientsApiCaller } from "../../utils/network-requests/clients-api-call";
import { validateForm1 } from "../../utils/Validation/validateForm1";

export default function Form1Container({
  setError,
  setClientModal,
  setMessage,
  clientId,
  nations,
  countryCode,
  form1,
  setForm1,
  changeStage,
  status,
  validateForm1Result,
  setValidateForm1Result,
}) {
  const submitHandler = (e) => {
    e.preventDefault();
    const { validationResult, form } = validateForm1(form1);
    console.log(validationResult);
    setValidateForm1Result(validationResult);
    setForm1({ ...form });
    if (Object.keys(validationResult).length === 0) {
      setClientModal("loader");

      let {
        fullname,
        date_of_birth,
        phone_number,
        email,
        gender,
        country_of_residence,
        funding_source,
        employment_status,
        investible_assets,
      } = form;

      gender = gender === "male" ? 1 : 2;
      investible_assets = Number(investible_assets) / 75;
      const data = {
        reqBody: {
          basic_details: {
            fullname,
            date_of_birth,
            phone_number,
            email,
            gender,
            country_of_residence,
            country_code: countryCode.code.slice(1),
          },
          investment_details: {
            employment_status,
            funding_source,
            investible_assets: Number(investible_assets),
          },
          client_id: clientId,
        },
      };

      clientsApiCaller
        .putForm(data)
        .then(({ response, error, unauthorized }) => {
          if (unauthorized) {
            setClientModal("");
            setError(true);
          } else if (!error) {
            if ((status === 2 || status === 5) && form.employment_status == 3)
              changeStage(5);
            else if (status === 1 && form.employment_status == 3)
              changeStage(4);
            else if (form.employment_status == 3) changeStage(3);
            else changeStage(2);

            setClientModal("");
          } else {
            setClientModal("message");
            if (response.email) {
              setMessage("Please enter a valid email address.");
            } else setMessage(JSON.stringify(response));
          }
        });
    }
  };

  const disableSumbit = () => {
    if (
      !form1.fullname ||
      !form1.date_of_birth ||
      !form1.phone_number ||
      !form1.email ||
      !Number(form1.investible_assets)
    ) {
      return true;
    }

    return false;
  };

  return (
    <Form1
      countryCode={countryCode}
      nations={nations}
      form1={form1}
      setForm1={setForm1}
      validateForm1Result={validateForm1Result}
      submitHandler={submitHandler}
      disableSumbit={disableSumbit}
    />
  );
}
