import { useEffect, useState } from "react";
import TextField from "../../../components/FormComponents/TextField/TextField";
import { useInput } from "../../../utils/custom-hooks/useInput";

export default function TextFieldContainer({
  label,
  className,
  setData,
  data,
  dataField,
  extraComponent,
  validationObj,
  notEditable,
  id,
}) {
  const { value, bind } = useInput(
    typeof data === "object" ? data[dataField] : ""
  );

  useEffect(() => {
    if (typeof data === "object") {
      setData({ ...data, [dataField]: value });
    } else {
      setData(value);
    }
  }, [value, setData]);

  const [show, setShow] = useState(false);

  return (
    <TextField
      id={id}
      bind={bind}
      label={label}
      className={className}
      extraComponent={extraComponent}
      validationObj={validationObj}
      notEditable={notEditable}
      show={show}
      setShow={setShow}
    />
  );
}
