import Form2 from "../../components/InviteClient/Form2";
import { clientsApiCaller } from "../../utils/network-requests/clients-api-call";
import { validateForm2 } from "../../utils/Validation/validateForm2";

export default function Form2Container({
  setError,
  setClientModal,
  setMessage,
  clientId,
  form2,
  setForm2,
  changeStage,
  status,
  validateForm2Result,
  setValidateForm2Result,
}) {
  const submitHandler = (e) => {
    e.preventDefault();
    const { validationResult, form } = validateForm2(form2);
    console.log(validationResult);
    setValidateForm2Result(validationResult);
    setForm2({ ...form });

    if (Object.keys(validationResult).length === 0) {
      setClientModal("loader");

      const investment_details = {};
      if (form.employer_name || form.employer_name === "")
        investment_details.employer_name = form.employer_name;
      if (form.employment_position || form.employment_position === "")
        investment_details.employment_position = form.employment_position;
      if (form.employer_address || form.employer_address === "")
        investment_details.employer_address = form.employer_address;

      const data = {
        reqBody: {
          client_id: clientId,
          investment_details,
        },
      };
      console.log(data);

      clientsApiCaller
        .putForm(data)
        .then(({ response, error, unauthorized }) => {
          setClientModal("");
          if (unauthorized) {
            setError(true);
          } else if (!error) {
            if (status === 1) changeStage(4);
            else if (status === 2 || status === 5) changeStage(5);
            else changeStage(3);
          } else {
            setClientModal("message");
            setMessage(JSON.stringify(response));
          }
        });
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    changeStage(1);
  };
  const skipNow = () => {
    if (status === 1) changeStage(4);
    else if (status === 2 || status === 5) changeStage(5);
    else changeStage(3);
  };

  const disableSumbit = () => {
    return false;
  };

  return (
    <Form2
      form2={form2}
      setForm2={setForm2}
      validateForm2Result={validateForm2Result}
      submitHandler={submitHandler}
      goBack={goBack}
      skipNow={skipNow}
      disableSumbit={disableSumbit}
    />
  );
}
