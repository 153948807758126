export const validateForm1 = (form1) => {
  // console.log(form1);
  const validationResult = {};

  const invalidNumber2 = (num) => {
    for (let digit of num) {
      if (!(digit >= 0 && digit <= 9)) return true;
      if (digit === " ") return true;
    }
    if (isNaN(Number(num))) return true;

    return false;
  };

  for (let key in form1) {
    if (typeof form1[key] === "string") {
      form1[key] = form1[key].trim();
    }
    if (["fullname", "email"].includes(key) && !form1[key]) {
      validationResult[key] = true;
      validationResult[key + "_error"] = "This field can not be empty";
    }
    if (
      ["investible_assets"].includes(key) &&
      (form1[key] === "" ||
        Number(form1[key]) === 0 ||
        Number(form1[key]) < 75 ||
        (typeof form1[key] === "string" && invalidNumber2(form1[key])))
    ) {
      validationResult[key] = true;
      validationResult[key + "_error"] = "Please enter a valid number";

      if (form1[key] === "" || Number(form1[key]) === 0) {
        validationResult[key + "_error"] = "This field can not be empty";
      }
      if (Number(form1[key]) < 75)
        validationResult[key + "_error"] = "Please enter minimum $1.";
    }
  }

  function isAdult(dob) {
    const age = new Date(dob);
    const today = new Date();

    if (today.getFullYear() - 18 === age.getFullYear()) {
      if (today.getMonth() === age.getMonth()) {
        return today.getDate() >= age.getDate();
      }
      return today.getMonth() > age.getMonth();
    }
    return today.getFullYear() - 18 > age.getFullYear();
  }

  if (!isAdult(form1.date_of_birth)) {
    validationResult.date_of_birth = true;
    validationResult.date_of_birth_error =
      "Age should be greater than or equal to 18";
  }

  return { validationResult, form: form1 };
};
