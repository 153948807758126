import Button from "../FormComponents/Button/Button";
import DocImage from "./DocImage";
import "./Form3.css";

export default function Form3({
  onDrop1,
  onDrop2,
  form3,
  validateForm3Result,
  submitHandler,
  goBack,
  fileInput1,
  fileInput2,
  removePanFront,
  removePanBack,
  removeAadharFront,
  removeAadharBack,
  reload,
  disableSubmit,
}) {
  return (
    <div>
      <form id="invite-form3" className="invite-client-form3">
        <div className="form3-header">PAN Card</div>
        <div className="form3-upload">
          <label
            id="invite-form3-file1"
            htmlFor="choose-file1"
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
            onDrop={onDrop1}
          >
            <div className="upload-icon">
              <img src="icons/uploadIcon.svg" alt="upload" />
            </div>
            <div>
              <span>Click to Upload</span> or <span>Drag & Drop</span> your
              files here
            </div>
            <div>Less than 10 MB</div>
            <input
              onChange={fileInput1}
              type="file"
              // accept=".png, .jpg, .jpeg"
              id="choose-file1"
              name="choose-file1"
              key={reload}
            />
          </label>
        </div>
        <div className="form3-images">
          <DocImage
            id="invite-form3-file1-img1"
            img={form3.panFront}
            front={true}
            clickHandler={removePanFront}
          />
          <DocImage
            id="invite-form3-file1-img2"
            img={form3.panBack}
            front={false}
            clickHandler={removePanBack}
          />
        </div>
        {validateForm3Result.pan && (
          <div id="invite-form3-file1-error" className="red-text">
            {validateForm3Result.panError}
          </div>
        )}
        <div className="form3-header">AADHAR Card - Front & Back</div>
        <div className="form3-upload">
          <label
            id="invite-form3-file2"
            htmlFor="choose-file2"
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={(e) => e.preventDefault()}
            onDragLeave={(e) => e.preventDefault()}
            onDrop={onDrop2}
          >
            <div className="upload-icon">
              <img src="icons/uploadIcon.svg" alt="upload" />
            </div>
            <div>
              <span>Click to Upload</span> or <span>Drag & Drop</span> your
              files here
            </div>
            <div>
              Please make sure that the address is visible. Details like name,
              photo and address are clear and unobstructed.
            </div>
            <div>Less than 10 MB</div>
            <input
              onChange={fileInput2}
              type="file"
              // accept="image/jpeg, image/png"
              id="choose-file2"
              name="choose-file2"
              key={reload}
            />
          </label>
        </div>
        <div className="form3-images">
          <DocImage
            id="invite-form3-file2-img1"
            img={form3.aadharFront}
            front={true}
            clickHandler={removeAadharFront}
          />
          <DocImage
            id="invite-form3-file2-img2"
            img={form3.aadharBack}
            front={false}
            clickHandler={removeAadharBack}
          />
        </div>
        {validateForm3Result.aadhar && (
          <div id="invite-form3-file2-error" className="red-text">
            {validateForm3Result.aadharError}
          </div>
        )}
        <div className="form3-buttons">
          <Button
            id="invite-form3-goback-button"
            label="BACK"
            className="goback-button"
            handleClick={goBack}
          />
          <Button
            id="invite-form3-submit-button"
            label="PROCEED TO VERIFICATION"
            className="form1-button form2-button"
            handleClick={submitHandler}
            // disabled={disableSubmit()}
          />
        </div>
      </form>
    </div>
  );
}
