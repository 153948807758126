import moment from "moment";
import { useEffect, useState } from "react";
import ReviewBuyOrder from "../../components/BuyOrder/ReviewBuyOrder";
import ReviewSellOrder from "../../components/SellOrder/ReviewSellOrder";
import { ordersApiCaller } from "../../utils/network-requests/orders-api-call";

export default function OrderContainer({
  setClientModal,
  setMessage,
  clientId,
  setError,
  setHeading,
}) {
  const [loading, setLoading] = useState(true);
  const [pending, setPending] = useState(true);

  const [data, setData] = useState({});

  useEffect(() => {
    setClientModal("loader");
    ordersApiCaller
      .getOrders()
      .then(({ response1, error1, response, error, unauthorized }) => {
        console.log({ response, response1 });
        if (unauthorized) {
          setClientModal("");
          setError(true);
        } else if (error || error1) {
          setClientModal("message");
          setMessage(
            "There was an error while fetching order details. Please try again later or you can contact us."
          );
        } else {
          setClientModal("");
          const obj = {
            stockSymbol: response1.symbol,
            stockName: response1.symbol_name,
            orderType:
              response1.order_type?.slice(0, 1)?.toUpperCase() +
              response1.order_type?.slice(1),
            stockPrice: response1.side === "buy" ? response.ask : response.bid,
            side: response1.side,
            quote_id: response.id,
            status: response1.status,
            status_group: response1.status_group,
          };

          if (obj.orderType !== "Market") {
            obj.expiry = response1.time_in_force === "day" ? "GTD" : "GTC";
          }
          if (obj.orderType === "Market") {
            if (response1.quantity) {
              obj.numOfShares = response1.quantity;
              obj.amount = parseFloat(
                (obj.numOfShares * obj.stockPrice).toFixed(2)
              );
              obj.amountEst = true;
            } else {
              obj.amount = response1.amount_cash;
              obj.numOfShares = parseFloat(
                (obj.amount / obj.stockPrice).toFixed(8)
              );
              obj.qtyEst = true;
            }
          } else {
            obj.numOfShares = response1.quantity;
            obj.limitPrice =
              obj.orderType === "Limit"
                ? response1.limit_price
                : response1.stop_price;
            obj.amount = parseFloat(
              (obj.numOfShares * obj.limitPrice).toFixed(2)
            );
            obj.amountEst = true;
          }

          obj.totalPrice = parseFloat(obj.amount.toFixed(2));

          if (response1.status !== "CUSTOMER_APPROVAL_PENDING") {
            setPending(false);
            console.log("juadnju", { response1, obj });
          }
          setData(obj);
          setLoading(false);
        }
      });
  }, []);

  const submitOrder = (action) => {
    const reqBody = { action, quote_id: data.quote_id };
    const heading = action === "approve" ? "Thanks you!" : "Rejected Order";
    const message =
      action === "approve"
        ? `Your ${data.side} order for ${data.stockSymbol} shares has been queued. You will receive a confirmation once the order is placed.`
        : `Your ${data.side} order for ${data.stockSymbol} shares has been rejected. Please contact your investment advisor to place a new order.`;

    setClientModal("loader");
    if (data.status !== "CUSTOMER_APPROVAL_PENDING") {
      setClientModal("message");
      setMessage(
        "The order has already been " +
          (data.status === "CUSTOMER_REJECTED" ? "rejected" : "approved")
      );
      return;
    }
    ordersApiCaller
      .submitOrder({ reqBody })
      .then(({ response, error, unauthorized }) => {
        if (unauthorized) {
          setClientModal("");
          setError(true);
        } else if (error) {
          setClientModal("message");
          setMessage(JSON.stringify(response));
        } else {
          if (data.status === "CUSTOMER_APPROVAL_PENDING") {
            setData({
              ...data,
              status: action === "approve" ? "NEW" : "CUSTOMER_REJECTED",
            });
            // setClientModal("confirmOrder");
            // setHeading(heading);
            // setMessage(message);
            setClientModal("");
            setPending(false);
          }
        }
      });
  };

  return loading ? (
    <div style={{ height: "calc(100vh - 65px)" }}></div>
  ) : pending ? (
    data.side === "buy" ? (
      <ReviewBuyOrder data={data} submitOrder={submitOrder} />
    ) : (
      <ReviewSellOrder data={data} submitOrder={submitOrder} />
    )
  ) : (
    <div id="not-pending" className="not-pending">
      <div>
        <div>
          {data.status === "CUSTOMER_REJECTED"
            ? "Rejected Order"
            : "Thank You!"}
        </div>
        <div id="not-pending-message">
          {data.status === "CUSTOMER_REJECTED"
            ? `Your ${data.side} order for ${data.stockSymbol} shares has been
            rejected. Please contact your investment advisor to place a new
            order.`
            : `Your ${data.side} order for ${data.stockSymbol} shares has been queued. You will receive a confirmation once the order is placed.`}
        </div>
        <div>You can safely close this window.</div>
      </div>
    </div>
  );
}
