import { useEffect, useState } from "react";
import Form3 from "../../components/InviteClient/Form3";
import { clientsApiCaller } from "../../utils/network-requests/clients-api-call";
import { validateForm3 } from "../../utils/Validation/validateForm3";

export default function Form3Container({
  setError,
  setClientModal,
  setMessage,
  documentIds,
  setDocumentIds,
  clientId,
  form3,
  form1,
  setForm3,
  changeStage,
  status,
  validateForm3Result,
  setValidateForm3Result,
}) {
  const [restart, setRestart] = useState(1);
  useEffect(() => {
    if (restart) {
      setTimeout(() => {
        setClientModal("loader");

        clientsApiCaller
          .getForm({
            form: "form3",
            params: { client_id: clientId },
          })
          .then(({ response, error, unauthorized }) => {
            if (unauthorized) {
              setClientModal("");
              setError(true);
            } else if (error) {
              setClientModal("message");
              let message = "";
              if (response.obj3) {
                for (let err in response.obj3) {
                  message += JSON.stringify(response.obj3[err]) + " ";
                }
              }
              setMessage(message);
            } else {
              if (response.obj1) setForm3({ ...form3, ...response.obj1 });
              if (response.obj2)
                setDocumentIds({ ...documentIds, ...response.obj2 });
              setClientModal("");
            }
            setRestart(0);
          });
      }, 1000);
    }
  }, [restart]);

  const submitHandler = (e) => {
    e.preventDefault();
    const validationResult = validateForm3(form3);
    console.log(validationResult);
    setValidateForm3Result(validationResult);

    if (Object.keys(validationResult).length === 0) {
      setClientModal("loader");
      clientsApiCaller
        .initSDK({ reqBody: { doc_type: 3 } })
        .then(({ response, error, unauthorized }) => {
          if (unauthorized) {
            setClientModal("");
            setError(true);
          } else if (!error) {
            console.log(response);
            const { id, document_type } = response;

            clientsApiCaller
              .generateCheck({
                reqBody: { applicant_id: id, doc_type: document_type },
              })
              .then(({ response, error, unauthorized }) => {
                if (unauthorized) {
                  setClientModal("");
                  setError(true);
                } else if (!error) {
                  setClientModal("");
                  changeStage(4);
                } else {
                  setClientModal("message");
                  setMessage(JSON.stringify(response));
                }
              });
          } else {
            setClientModal("message");
            setMessage(JSON.stringify(response));
          }
        });
    }
  };

  const disableSubmit = () => {
    if (
      !(form3.panFront || form3.panBack) ||
      !(form3.aadharFront || form3.aadharBack)
    ) {
      return true;
    }
    return false;
  };

  const goBack = (e) => {
    e.preventDefault();
    if (form1.employment_status == 3) {
      changeStage(1);
    } else {
      changeStage(2);
    }
  };

  const supportedExtensions = ["jpeg", "jpg", "png"];
  const [documentNames, setDocumentNames] = useState({
    panFront: "",
    panBack: "",
    aadharFront: "",
    aadharBack: "",
  });

  const uploadImage1 = (files) => {
    if (files) {
      const arr = files.type.split("/");
      const fileType = arr[arr.length - 1];

      if (
        documentNames.panFront === files.name ||
        documentNames.panBack === files.name
      ) {
        return;
      }
      if (!supportedExtensions.includes(fileType)) {
        setClientModal("message");
        setMessage("Please upload a valid image format (.jpg, .jpeg or .png).");
        return;
      }

      setClientModal("loader");
      const reqBody = {
        client: clientId,
        document_type: 3,
        document_side: form3.panFront === "" ? "front" : "back",
        image: files,
      };

      const postImage = () => {
        clientsApiCaller
          .postImage({ reqBody })
          .then(({ response, error, unauthorized }) => {
            if (unauthorized) {
              setClientModal("");
              setError(true);
            } else if (!error) {
              const fileReader = new FileReader();
              fileReader.readAsDataURL(files);
              fileReader.addEventListener("load", function () {
                if (form3.panFront === "") {
                  setForm3({ ...form3, panFront: this.result });
                  setDocumentIds({ ...documentIds, panFront: response.id });
                  setDocumentNames({ ...documentNames, panFront: files.name });
                  setClientModal("");
                } else {
                  setForm3({ ...form3, panBack: this.result });
                  setDocumentIds({ ...documentIds, panBack: response.id });
                  setDocumentNames({ ...documentNames, panBack: files.name });
                  setClientModal("");
                }
              });
            } else {
              if (form3.panBack) {
                setForm3({ ...form3, panBack: "" });
              }
              setClientModal("message");
              setMessage(JSON.stringify(response));
            }
          });
      };

      if (form3.panFront && form3.panBack) {
        clientsApiCaller
          .deleteImage({ params: { document_id: documentIds.panBack } })
          .then(({ response, error, unauthorized }) => {
            if (unauthorized) {
              setClientModal("");
              setError(true);
            } else if (!error) {
              postImage();
            } else {
              setClientModal("message");
              setMessage(JSON.stringify(response));
            }
          });
      } else {
        postImage();
      }
    }
  };

  const onDrop1 = (e) => {
    e.preventDefault();
    let files = e.dataTransfer.files;
    console.log(files);
    files = files[0];

    uploadImage1(files);
  };

  const fileInput1 = (e) => {
    const files = e.target.files[0];
    uploadImage1(files);
  };

  const uploadImage2 = (files) => {
    if (files) {
      const arr = files.type.split("/");
      const fileType = arr[arr.length - 1];

      if (
        documentNames.aadharFront === files.name ||
        documentNames.aadharBack === files.name
      ) {
        return;
      }
      if (!supportedExtensions.includes(fileType)) {
        setClientModal("message");
        setMessage("Please upload a valid image format (.jpg, .jpeg or .png).");
        return;
      }

      setClientModal("loader");
      const reqBody = {
        client: clientId,
        document_type: 1,
        document_side: form3.aadharFront === "" ? "front" : "back",
        image: files,
      };

      const postImage = () => {
        clientsApiCaller
          .postImage({ reqBody })
          .then(({ response, error, unauthorized }) => {
            if (unauthorized) {
              setClientModal("");
              setError(true);
            } else if (!error) {
              const fileReader = new FileReader();
              fileReader.readAsDataURL(files);
              fileReader.addEventListener("load", function () {
                if (form3.aadharFront === "") {
                  setForm3({ ...form3, aadharFront: this.result });
                  setDocumentIds({ ...documentIds, aadharFront: response.id });
                  setDocumentNames({
                    ...documentNames,
                    aadharFront: files.name,
                  });
                  setClientModal("");
                } else {
                  setForm3({ ...form3, aadharBack: this.result });
                  setDocumentIds({ ...documentIds, aadharBack: response.id });
                  setDocumentNames({
                    ...documentNames,
                    aadharBack: files.name,
                  });
                  setClientModal("");
                }
              });
            } else {
              if (form3.aadharBack) {
                setForm3({ ...form3, aadharBack: "" });
              }
              setClientModal("message");
              setMessage(JSON.stringify(response));
            }
          });
      };

      if (form3.aadharFront && form3.aadharBack) {
        clientsApiCaller
          .deleteImage({ params: { document_id: documentIds.aadharBack } })
          .then(({ response, error, unauthorized }) => {
            if (unauthorized) {
              setClientModal("");
              setError(true);
            } else if (!error) {
              postImage();
            } else {
              setClientModal("message");
              setMessage(JSON.stringify(response));
            }
          });
      } else {
        postImage();
      }
    }
  };

  const onDrop2 = (e) => {
    e.preventDefault();
    let files = e.dataTransfer.files;
    console.log(files);
    files = files[0];

    uploadImage2(files);
  };

  const fileInput2 = (e) => {
    const files = e.target.files[0];
    uploadImage2(files);
  };

  const [reload, setReload] = useState(0);

  const removePanFront = () => {
    setClientModal("loader");
    clientsApiCaller
      .deleteImage({ params: { document_id: documentIds.panFront } })
      .then(({ response, error, unauthorized }) => {
        if (unauthorized) {
          setClientModal("");
          setError(true);
        } else if (!error) {
          setForm3({ ...form3, panFront: "" });
          setDocumentNames({ ...documentNames, panFront: "" });
          setReload(reload + 1);
          setClientModal("");
        } else {
          setClientModal("message");
          setMessage(JSON.stringify(response));
        }
      });
  };
  const removePanBack = () => {
    setClientModal("loader");
    clientsApiCaller
      .deleteImage({ params: { document_id: documentIds.panBack } })
      .then(({ response, error, unauthorized }) => {
        if (unauthorized) {
          setClientModal("");
          setError(true);
        } else if (!error) {
          setForm3({ ...form3, panBack: "" });
          setDocumentNames({ ...documentNames, panBack: "" });
          setReload(reload + 1);
          setClientModal("");
        } else {
          setClientModal("message");
          setMessage(JSON.stringify(response));
        }
      });
  };
  const removeAadharFront = () => {
    setClientModal("loader");
    clientsApiCaller
      .deleteImage({ params: { document_id: documentIds.aadharFront } })
      .then(({ response, error, unauthorized }) => {
        if (unauthorized) {
          setClientModal("");
          setError(true);
        } else if (!error) {
          setForm3({ ...form3, aadharFront: "" });
          setDocumentNames({ ...documentNames, aadharFront: "" });
          setReload(reload + 1);
          setClientModal("");
        } else {
          setClientModal("message");
          setMessage(JSON.stringify(response));
        }
      });
  };
  const removeAadharBack = () => {
    setClientModal("loader");
    clientsApiCaller
      .deleteImage({ params: { document_id: documentIds.aadharBack } })
      .then(({ response, error, unauthorized }) => {
        if (unauthorized) {
          setClientModal("");
          setError(true);
        } else if (!error) {
          setForm3({ ...form3, aadharBack: "" });
          setDocumentNames({ ...documentNames, aadharBack: "" });
          setReload(reload + 1);
          setClientModal("");
        } else {
          setClientModal("message");
          setMessage(JSON.stringify(response));
        }
      });
  };
  return (
    <Form3
      onDrop1={onDrop1}
      onDrop2={onDrop2}
      form3={form3}
      setForm3={setForm3}
      validateForm3Result={validateForm3Result}
      submitHandler={submitHandler}
      goBack={goBack}
      fileInput1={fileInput1}
      fileInput2={fileInput2}
      removePanFront={removePanFront}
      removePanBack={removePanBack}
      removeAadharFront={removeAadharFront}
      removeAadharBack={removeAadharBack}
      reload={reload}
      disableSubmit={disableSubmit}
    />
  );
}
