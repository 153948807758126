import "./Stages.css";

export default function Stages({ stage }) {
  return (
    <div className="stages">
      <div>
        <div>
          <div className="stages-circle teal">
            {stage === 1 ? 1 : <img src="/icons/done.svg" alt="done icon" />}
          </div>
          <div className={stage === 1 ? "" : "hide-stage"}>
            Personal Details
          </div>
        </div>
        <div className="stages-line"></div>

        <div>
          <div className={"stages-circle " + (stage > 1 ? "teal" : "gray")}>
            {stage <= 2 ? 2 : <img src="/icons/done.svg" alt="done icon" />}
          </div>
          <div
            className={
              (stage < 2 ? "stages-white-text" : "") +
              (stage === 2 ? "" : "hide-stage")
            }
          >
            Professional Details
          </div>
        </div>
        <div className="stages-line"></div>

        <div>
          <div className={"stages-circle " + (stage > 2 ? "teal" : "gray")}>
            {stage <= 3 ? 3 : <img src="/icons/done.svg" alt="done icon" />}
          </div>
          <div
            className={
              (stage < 3 ? "stages-white-text" : "") +
              (stage === 3 ? "" : "hide-stage")
            }
          >
            Documents
          </div>
        </div>
        <div className="stages-line"></div>

        <div>
          <div className={"stages-circle " + (stage > 3 ? "teal" : "gray")}>
            {stage <= 4 ? 4 : <img src="/icons/done.svg" alt="done icon" />}
          </div>
          <div
            className={
              (stage < 4 ? "stages-white-text" : "") +
              (stage === 4 ? "" : "hide-stage")
            }
          >
            Verification
          </div>
        </div>
        <div className="stages-line"></div>

        <div>
          <div className={"stages-circle " + (stage > 4 ? "teal" : "gray")}>
            {stage <= 5 ? 5 : <img src="/icons/done.svg" alt="done icon" />}
          </div>
          <div
            className={
              (stage < 5 ? "stages-white-text" : "") +
              (stage === 5 ? "" : "hide-stage")
            }
          >
            <span className="tc-hide">Terms & Conditions</span>
            <span className="tc-show">T&Cs</span>
          </div>
        </div>
      </div>
    </div>
  );
}
