import SelectField from "../../../components/FormComponents/SelectField/SelectField";

export default function SelectFieldContainer({
  label,
  className,
  setData,
  data,
  dataField,
  options,
  validationObj,
  multiple,
  id,
}) {
  const onChange = (e) => {
    if (multiple) {
      console.log(e.target.value);
      console.log(e.target.selectedOptions);
      setData({
        ...data,
        [dataField]: Array.from(
          e.target.selectedOptions,
          (option) => option.value
        ),
      });
    } else {
      setData({ ...data, [dataField]: e.target.value });
    }
  };

  return (
    <SelectField
      id={id}
      label={label}
      className={className}
      value={data[dataField]}
      onChange={onChange}
      options={options}
      validationObj={validationObj}
      multiple={multiple}
    />
  );
}
