import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Main from "../../components/Main/Main";

export default function MainContainer() {
  const [clientModal, setClientModal] = useState("");
  const [message, setMessage] = useState("");
  const [heading, setHeading] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const path = location.pathname;
    const token =
      location.search.slice(7) ||
      (path === "/onboarding"
        ? sessionStorage.getItem("client_token")
        : sessionStorage.getItem("order_token"));
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
        setLoading(false);
        history.push("/invalid-request");
        return {};
      }
    };

    const { user_id, token_type } = parseJwt(token);

    if (path === "/onboarding") sessionStorage.setItem("client_token", token);
    else {
      sessionStorage.setItem("order_token", token);
      sessionStorage.setItem("order_id", parseJwt(token).order_id);
    }
    const old_user_id = sessionStorage.getItem("client_id");
    if (old_user_id) sessionStorage.setItem("old_client_id", old_user_id);
    sessionStorage.setItem("client_id", user_id);

    if (
      (path === "/onboarding" || path === "/order" || path === "/thank-you") &&
      token_type === "client"
    ) {
      setLoading(false);
      history.replace(path);
    } else {
      setLoading(false);
      history.push("/invalid-request");
    }
  }, []);

  return loading ? null : (
    <div>
      <Main
        error={error}
        setError={setError}
        clientModal={clientModal}
        setClientModal={setClientModal}
        clientId={sessionStorage.getItem("client_id")}
        message={message}
        setMessage={setMessage}
        heading={heading}
        setHeading={setHeading}
      />
      <div
        id="contact-us"
        style={{
          color: "#778192",
          display: "flex",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <span style={{ textAlign: "center" }}>
          If you have any questions or issues, please feel free to&nbsp;
          <a
            style={{ color: "#0E91A0", cursor: "pointer", display: "inline" }}
            href="mailto:support@winvesta.in"
          >
            contact us
          </a>
        </span>
      </div>
    </div>
  );
}
