import axios from "axios";
import { apiCaller } from "./api-call";
import { baseUrl } from "../Constants/UrlList";

function getHeader() {
  return {
    Authorization: `Bearer ${sessionStorage.getItem("client_token")}`,
  };
}

export const clientsApiCaller = {
  generateCheck: async function ({ reqBody }) {
    const url = "/partner-client/onfido/check";
    const { response, error, status } = await apiCaller.post({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      reqBody,
      headers: getHeader(),
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    return {
      response,
      error,
      unauthorized: false,
    };
  },
  initSDK: async function ({ reqBody }) {
    const url = "/partner-client/onfido/applicant";
    const { response, error, status } = await apiCaller.post({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      reqBody,
      headers: getHeader(),
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    return {
      response,
      error,
      unauthorized: false,
    };
  },
  putForm: async function ({ reqBody }) {
    const url = "/partner-client/onboarding/";

    const { response, error, status } = await apiCaller.put({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      reqBody,
      headers: getHeader(),
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    return {
      response,
      error,
      unauthorized: false,
    };
  },
  getImage: async function ({ params }) {
    let url =
      "/partner-client/upload-document?document_id=" + params.document_id;

    url = process.env.NODE_ENV !== "production" ? url : baseUrl + url;
    try {
      const { data, headers, status } = await axios.get(url, {
        headers: getHeader(),
        responseType: "arraybuffer",
      });

      let image = btoa(
        new Uint8Array(data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return {
        response: `data:${headers[
          "content-type"
        ].toLowerCase()};base64,${image}`,
        error: false,
        unauthorized: false,
      };
    } catch (error) {
      if (error.response.status === 401) {
        return {
          unauthorized: true,
        };
      } else {
        return {
          response: "There was an error while fetching the image!",
          error: true,
          unauthorized: false,
        };
      }
    }
  },
  postImage: async function ({
    reqBody: { client, document_type, document_side, image },
  }) {
    const url = "/partner-client/upload-document";

    const formData = new FormData();
    formData.append("client", client);
    formData.append("document_type", document_type);
    formData.append("document_side", document_side);
    formData.append("image", image, image.name);

    const { response, error, status } = await apiCaller.post({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      reqBody: formData,
      headers: { ...getHeader(), "Content-Type": "multipart/form-data" },
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    return {
      response,
      error,
      unauthorized: false,
    };
  },
  deleteImage: async function ({ params }) {
    const url =
      "/partner-client/upload-document?document_id=" + params.document_id;
    const { response, error, status } = await apiCaller.delete({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      headers: getHeader(),
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    return {
      response,
      error,
      unauthorized: false,
    };
  },
  getForm: async function ({ form, params }) {
    const url =
      (form === "form3"
        ? "/partner-client/upload-document"
        : "/partner-client/onboarding/") +
      "?client_id=" +
      params.client_id;
    const { response, error, status } = await apiCaller.get({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      headers: getHeader(),
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    if (form === "form3" && response && response.length) {
      const documentsArr = response;
      let obj1 = {};
      let obj2 = {};
      let obj3 = {};
      for (let el of documentsArr) {
        const { response, error, unauthorized } =
          await clientsApiCaller.getImage({
            params: { document_id: el.id },
          });

        if (unauthorized) {
          return {
            unauthorized: true,
          };
        } else if (error) {
          if (el.document_type === 1) {
            if (el.document_side === "front") {
              obj3.aadharFront = response;
            } else {
              obj3.aadharBack = response;
            }
          } else {
            if (el.document_side === "front") {
              obj3.panFront = response;
            } else {
              obj3.panBack = response;
            }
          }
        } else {
          if (el.document_type === 1) {
            if (el.document_side === "front") {
              obj1.aadharFront = response;
              obj2.aadharFront = el.id;
            } else {
              obj1.aadharBack = response;
              obj2.aadharBack = el.id;
            }
          } else {
            if (el.document_side === "front") {
              obj1.panFront = response;
              obj2.panFront = el.id;
            } else {
              obj1.panBack = response;
              obj2.panBack = el.id;
            }
          }
        }
      }

      return {
        response: { obj1, obj2, obj3 },
        error: Object.keys(obj3).length ? true : false,
        unauthorized: false,
      };
    }

    return {
      response,
      error,
      unauthorized: false,
    };
  },
  getCountry: async function () {
    const url = "/country/list";
    const { response, error, status } = await apiCaller.get({
      url: process.env.NODE_ENV !== "production" ? url : baseUrl + url,
      headers: getHeader(),
    });

    if (status === 401) {
      return {
        unauthorized: true,
      };
    }

    return {
      response,
      error,
      unauthorized: false,
    };
  },
  getDoc: async function ({ params }) {
    let url = "/partner/disclosure?partner_id=" + params.partnerId;

    url = process.env.NODE_ENV !== "production" ? url : baseUrl + url;
    try {
      const { data, headers } = await axios.get(url, {
        headers: getHeader(),
        responseType: "arraybuffer",
      });

      let pdf = btoa(
        new Uint8Array(data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );
      return {
        response: {
          pdf: `data:${headers["content-type"].toLowerCase()};base64,${pdf}`,
          buffer: data,
        },
        error: false,
        unauthorized: false,
      };
    } catch (error) {
      if (error.response.status === 401) {
        return {
          unauthorized: true,
        };
      } else {
        return {
          response: "There was an error while fetching the image!",
          error: true,
          unauthorized: false,
        };
      }
    }
  },
};
